import React from "react";

export default function Usluge(){
   return(
      <div className="usluge" id="usluge">
         <h1>Usluge</h1>
         <div>
            <img src="slike/usluge1.png" alt="logo"/>
            <img src="slike/usluge2.png" alt="logo" className="nes" />
            <img src="slike/usluge3.png" alt="logo"/>
         </div>
      </div>
   )


}